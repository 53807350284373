//
var siteURL = document.location.origin;
var rootURL = config.urls.root;
var templatesURL = config.urls.templates;

//
var animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
var transitionEnd = 'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd';

//
var supportsTouch = 'ontouchstart' in window || navigator.maxTouchPoints;

// Lazzzy
$.fn.loadImage = function() {
    var toLoad;
    return this.each(function() {
        if ($(window).width() > 768) {
            toLoad = $(this).data('original');
        } else {
            toLoad = $(this).data('medium');
        }
        var $img = $(this);			
        $('<img />').attr('src', toLoad).imagesLoaded(function() {
            $img.attr('src', toLoad).addClass('loaded').removeClass('loading');
            $(window).trigger('resize');
        });
    });
};
$.fn.loadBgImage = function() {
    return this.each(function() {
        var toLoad;
        if ($(window).width() > 768) {
            toLoad = $(this).data('original');
        } else {
            toLoad = $(this).data('medium');
        }
        var $img = $(this);
        $('<div />').css('background-image','url(' + toLoad + ')').imagesLoaded(function() {
            $img.css('background-image','url(' + toLoad + ')').addClass('loaded').removeClass('loading');
            $(window).trigger('resize');
        });
    });
};
function loadImages() {
    $('img.lazy').each(function() {
        var $img = $(this);
        $img.waypoint(function() {
            $img.loadImage();
        }, { offset: '100%' });
    });
    $('.bg.lazy').each(function() {
        var $img = $(this);
        $img.waypoint(function() {
            $img.loadBgImage();
        }, { offset: '100%' });
    });
}
loadImages();

//
disableScroll = false;
$(document).on('touchmove',function(e) {
    if (disableScroll) {
        e.preventDefault();
    }
});

//
$(document).on('scroll', function() {
    if ($(window).scrollTop() >= 1) {
        $('body').addClass('has--scrolled');
    } else {
        $('body').removeClass('has--scrolled');
        $('header.top ul.sub-menu').removeClass('quick-hide');
    }
});

// Menu interactions
function menus() {
    $('ul.menu li').removeClass('active');
    $('ul.menu li').each(function() {
        var aClass = $(this).attr('class');
        if (aClass !== undefined) {
            var classes = aClass.split(/\s+/);
            for (var i=0,len=classes.length; i<len; i++) {
                if ($('body').hasClass(classes[i])) {
                    $(this).addClass('active');
                }
            }
        }
    });
}

$(document).on('click', 'header.top ul.menu > li.contact a', function(e) {
    e.preventDefault();
    var $this = $(this),
    section = $this.attr('href');
    $.smoothScroll({
        scrollTarget: section,
        //easing: 'easeInOutExpo',
        speed: 1000
    });
});

menus();

//
function imgToSVG() {

    $('img.svg').each(function() {
        var $img = $(this),
        imgClass = $img.attr('class'),
        imgURL = $img.attr('src');
        $.get(imgURL, function(data) {
            var $svg = $(data).find('svg');  // Get the SVG tag, ignore the rest
            if (typeof imgClass !== 'undefined') {  // Add replaced image class to the new SVG
                $svg = $svg.attr('class', imgClass+' replaced-svg');
            }
            $svg = $svg.removeAttr('xmlns:a'); // Remove any invalid XML tags as per http://validator.w3.org
            $img.replaceWith($svg); // Replace image with new SVG
        }, 'xml');
    });
}
imgToSVG();

//
function internalLinks() {
    $('a[rel=internal]').each(function() {
        $(this).attr('data-pjax', '');
    });
}
internalLinks();

//
function isotope() {

    var $overviews = $('.news--layout,.press-awards--layout, .work-detail--layout, .news-detail--layout');
    if ($overviews.length > 0) {
        $overviews.imagesLoaded(function() {
            $overviews.isotope({
                transitionDuration: 0,
                itemSelector : '.article, .content',
                layoutMode: 'masonry',
                masonry: {
                    columnWidth: '.grid-sizer'
                }
            });
        });
    }

    var $peopleLayout = $('.practice-people--layout');
    if ($peopleLayout.length > 0) {
        $peopleLayout.imagesLoaded(function() {
            $peopleLayout.isotope({
                transitionDuration: 0,
                itemSelector : '.article, .content, .person',
                layoutMode: 'fitRows',
                masonry: {
                    columnWidth: '.grid-sizer'
                }
            });
        });
    }

}
isotope();

//
function mapInit() {
    var map = null;
    map = render_map($('.map-canvas'));
}
//mapInit(); /* disabled by dynamit */

$(document).on('click', 'footer.main section.footer p.map-trigger a', function(e) {
    e.preventDefault();
    var $this = $(this);
    $('footer.main section.map').toggleClass('is--active');
    $this.text($this.text() == 'Open map' ? 'Close map' : 'Open map');
});


if (!supportsTouch) {

    $(document).on('mouseover', 'header.top.lg ul.menu > li.has--sub-menu a', function(e) {
        var $this = $(this),
        $sub = $this.parent('li').find('ul.sub-menu'),
        menu = $this.parent('li').data('menu');

        $('header.top ul.sub-menu.is--active').not($sub).addClass('quick-hide');

        $this.parent('li').addClass('is--active');
        $sub.addClass('is--active');

        $('body').addClass('sub-menu--open ' + menu + '-is--open');
    });

    $(document).on('mouseleave', 'header.top.lg ul.menu > li.has--sub-menu', function(e) {
        var $this = $(this),
        $sub = $this.find('ul.sub-menu'),
        menu = $this.data('menu');

        $('header.top ul.sub-menu.is--active').not($sub).removeClass('quick-hide');

        $this.removeClass('is--active');
        if (!$this.is('.active')) {
            $sub.removeClass('is--active');
        }
        $('body').removeClass('sub-menu--open ' + menu + '-is--open');
    });

}

//
function work() {

    if ($('body').is('.work')) {
        $('li.work > ul.sub-menu').addClass('is--active');
    } else {
        $('li.work > ul.sub-menu').removeClass('is--active');
    }

}

$(document).on('click', 'section.work--overview h2.page-title a', function(e) {
    e.preventDefault();
    var $this = $(this),
    view = $this.data('view');
    $('section.work--overview h2.page-title a').removeClass('active');
    $this.addClass('active');
    $('section.work--overview section[data-view]').removeClass('is--active');
    $('section.work--overview section[data-view=' + view + ']').addClass('is--active');
});

work();

//
function practice() {

    if ($('body').is('.practice')) {
        $('li.practice ul.sub-menu').addClass('is--active');
    } else {
        $('li.practice ul.sub-menu').removeClass('is--active');
    }
}

function bgDark() {
    var $logo = $('img[data-logo-light]');

    if ($('body[class*="practice"]')[0]) {
        $('body').addClass('bg-dark');
        $logo.attr('src', $logo.attr('data-logo-dark'));
    } else {
        $('body').removeClass('bg-dark');
        $logo.attr('src', $logo.attr('data-logo-light'));
    }
}
bgDark();

$(document).on('click', '.practice-people--layout .person:not(.is--active) .meta a', function(e) {
    e.preventDefault();
    var $this = $(this),
    $parent = $this.parents('.person');

	$('.practice-people--layout .person').removeClass().addClass('col-xs-9 col-sm-6 person');
    $('.practice-people--layout .person .title').removeClass().addClass('col-xs-18 title');
    $('.practice-people--layout .person .biography').removeClass('is--active');
    $('.practice-people--layout .person .meta a').text('More');

	$parent.removeClass().addClass('col-xs-18 col-sm-12 person is--active');

	$parent.find('.title').addClass('col-xs-18 col-sm-9 title');
    $parent.find('.biography').addClass('is--active');
    $this.text('Close');
    $('.practice-people--layout').isotope('layout');

});
$(document).on('click', '.practice-people--layout .person.is--active .meta a', function(e) {
    e.preventDefault();
    var $this = $(this),
    $parent = $this.parents('.person');

	$parent.removeClass().addClass('col-xs-9 col-sm-6 person');
    $parent.find('.biography').removeClass('is--active');
    $parent.find('.title').removeClass().addClass('col-xs-18 title');
    $this.text('More');
    $('.practice-people--layout').isotope('layout');
});

practice();

function hideContactFooter() {

    var pollingInterval = 250,
        $contactFooter = $('.footer--contact');

    setTimeout(function(){

        // get URL
        var path = window.location.pathname;

        if (path.indexOf('/contact') === 0) {
            $contactFooter.hide();
        }
        else {
            $contactFooter.show();
        }

    }, pollingInterval);
}
hideContactFooter();

function initOwlCarousel() {
    var owl = $('.owl-carousel');
    owl.owlCarousel({
        items: 1,
        responsiveClass:true,
        nav: true,
        navText: [
            '<span aria-label="' + 'Previous' + '"></span>',
            '<span aria-label="' + 'Next' + '"></span>'
        ],
        loop: true,
        autoplay: false,
        autoHeight: true
    });
}
initOwlCarousel();

// Ajax
$.pjax.defaults.scrollTo = false;
$.pjax.defaults.timeout = 200000;

var $target = $('section.context');

function loadNewContent() {

    event.preventDefault();

    var $this = $(this),
    url = $this.attr('href');

    $target.addClass('is--loading');
    $('body').addClass('context-is--loading');

    $target.one(transitionEnd, function() {
        $.pjax({
            url: url,
            fragment: 'body',
            container: $target
        });
        $target.off(transitionEnd);
    });
}

function loadBodyClass() {
    var bodyClass = $('meta[name=body-class]').attr('content');
    if (bodyClass !== undefined) {
        $('body').addClass(bodyClass);
    }
}
loadBodyClass();

$(document).on('click', 'a[data-pjax]', loadNewContent);

$(document).on('pjax:end', function() {

    var bodyClass = $('meta[name=body-class]').attr('content');
    if (bodyClass !== undefined) document.body.className = bodyClass;
    loadImages();
    isotope();
    imgToSVG();
    internalLinks();
    menus();
    work();
    practice();
    bgDark();
    initOwlCarousel();
    hideContactFooter();
    $('body').scrollTop(0);
    $('header.top ul.sub-menu').removeClass('quick-hide');
    $target.removeClass('is--loading');
    $('body').removeClass('context-is--loading');

});
